<template>
  <div>
    <section class="container">
      <v-row class="d-flex justify-center">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card
              class="sobre__projeto_card"
              title="Construção metodológica dos mapas comunitários"
              title_alignment="start"
              borderColor="#E06919"
              titleColor="#E06919"
            >
              <template v-slot:conteudo>
                <p>
                  O processo de cadastramento das vítimas do desastre–crime
                  resultante do rompimento da barragem de Fundão em Mariana-MG
                  teve início em 2018, sob a condução da Cáritas Brasileira
                  Regional Minas Gerais, na condição de Assessoria Técnica
                  Independente aos Atingidos e Atingidas, em cumprimento ao
                  acordo firmado em Audiência Judicial, no dia 18 de outubro de
                  2017.
                </p>
                <p>
                  Em Mariana,
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'sobre_cadastro' }"
                    >o cadastramento dos atingidos e atingidas é composto de
                    cinco etapas</router-link
                  >. Dentre elas está a etapa 2: Cartografia Social Familiar, um
                  instrumento complementar aos eixos 1 e 2 do formulário de
                  cadastro, que consiste em um procedimento participativo,
                  aplicado por meio de oficinas, que visa levantar perdas e/ou
                  danos, atuando em questões que envolvem a espacialização
                  territorial, usos e ocupações dos terrenos atingidos, assim
                  como as relações existentes nessas áreas. A
                  <a
                    target="_blank"
                    href="http://www.monografias.ufop.br/handle/35400000/3979
"
                    class="pink--text"
                    style="text-decoration: none;"
                    >construção metodológica das Cartografias Sociais</a
                  >
                  foi realizada em uma perspectiva dialógica, levando-se em
                  consideração as percepções e desejos dos sujeitos envolvidos.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="assessoria_card d-flex flex-column justify-center align-center"
        >
          <v-img
            max-width="100%"
            max-height="100%"
            src="../assets/projeto_mapa_comunitario_01.png"
            alt=""
          />
          <p style="font-size: 0.5em;">
            Atingidas realizando o desenho de Cartografia Social. Foto: Acervo
            Cáritas, 2018.
          </p>
        </v-col>
      </v-row>
    </section>
    <section class="container">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="10"
          md="8"
          class="d-flex justify-center align-center border__image"
        >
          <p class="ma-0" style="max-width: 95%">
            “A partir do desenho, realizado de forma muito específica e
            individualizada por cada família, consegue-se expressar muito
            claramente, o que, de fato, possuía grande significado para suas
            vidas. A memória afetiva e as lembranças que pareciam ter sido
            apagadas, tomadas pelo sofrimento e desesperança, são reativadas. A
            memória se torna presente no momento de realização da oficina, se
            transformando em um conjunto de informações espaciais,
            materializadas através do desenho manual livre do território
            atingido pelo rompimento da barragem de Fundão (LEITE, 2022, p.
            74-75).”
          </p>
        </v-col>
      </v-row>
    </section>
    <section class="container">
      <v-row class="d-flex justify-center align-center flex-md-row-reverse">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card
              class="sobre__projeto_card"
              borderColor="#E06919"
              titleColor="#E06919"
            >
              <template v-slot:conteudo>
                <p>
                  Cabe ainda dizer que, apesar de centradas na vivência de cada
                  núcleo familiar, em função do nível de detalhamento necessário
                  para o posterior elencar e contabilizar das perdas com vistas
                  ao pleito de reparação justa e integral, importa colocar que
                  as Cartografias Sociais Familiares ultrapassam os limites dos
                  terrenos e abarcam perdas não restritas aos danos materiais:
                </p>
                <p>
                  “As lembranças do período anterior ao desastre socioambiental,
                  reúnem uma série de acontecimentos cotidianos que ativam a
                  memória afetiva das pessoas e enaltecem o sentimento de
                  pertença aos seus territórios de origem. As festas
                  tradicionais, celebrações, festas juninas, cavalgadas, festas
                  dos padroeiros, folia de reis, entre diversas outras
                  manifestações culturais e religiosas relatadas nas oficinas,
                  enaltecem a singularidade do território, além de consolidar
                  uma referência cultural para a região, o que também contribui
                  para uma melhor compreensão do território e levantamento de
                  perdas e danos imateriais às quais estas famílias foram
                  submetidas (LEITE, 2022, p. 79-80).”
                </p>
                <p>
                  Posteriormente, a etapa 3: Vistoria surgiu como exigência
                  apontada pela Fundação Renova, ocorrendo na presença de sua
                  empresa subcontratada Synergia, da Cáritas e do núcleo
                  familiar. Com o propósito de constatar in loco a situação
                  atual de degradação das edificações e dos terrenos atingidos,
                  na vistoria é possível complementar as informações adquiridas
                  durante a aplicação do formulário e nas oficinas de
                  Cartografia Social Familiar a respeito das perdas e danos
                  sofridos pelos núcleos familiares atingidos.
                </p>
                <p>
                  Face à destruição dos terrenos e seus elementos, quer seja
                  pelo impacto da lama ou pelos saques e degradação temporal
                  ocorridos, a descaracterização dos mesmos frequentemente
                  impede a observação dos bens conforme existentes antes do
                  desastre–crime. Portanto, a autodeclaração é a principal — e,
                  em alguns casos, a única — fonte de informação para o
                  levantamento integral de perdas e danos.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="assessoria_card d-flex flex-column align-center justify-start"
        >
          <v-img
            max-width="100%"
            max-height="100%"
            src="../assets/projeto_mapa_comunitario_02.png"
            alt=""
          />
          <p style="font-size: 0.5em;">
            Realização de vistoria para diagnóstico atual dos terrenos em Bento
            Rodrigues. Foto: Gabriel Leite, 2019.
          </p>
        </v-col>
      </v-row>
    </section>
    <section class="container">
      <v-row class="d-flex justify-center">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card
              class="sobre__projeto_card"
              borderColor="#E06919"
              titleColor="#E06919"
            >
              <template v-slot:conteudo>
                <p>
                  A metodologia elaborada pela Cáritas para a caracterização dos
                  terrenos conforme suas características anteriores ao
                  rompimento da barragem de Fundão integra as fontes de
                  informação disponíveis no processo de cadastro. O trabalho de
                  elaboração e aplicação da metodologia das etapas 2 e 3 foi
                  realizado por equipe multidisciplinar possibilitando a
                  interpretação dos dados sobre as diversas perspectivas de
                  caracterização arquitetônica e agrária tendo em vista o
                  contexto rururbano<sup>1</sup> no qual se assentam as
                  comunidades atingidas. Toda a metodologia detidamente
                  desenvolvida para essas etapas está descrita na página sobre o
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'sobre_cadastro' }"
                    >Cadastro</router-link
                  >.
                </p>
                <p>
                  Os dados levantados são fundamentais para que os Núcleos
                  Familiares tenham um relato completo do que lhes foi retirado
                  e, consequentemente, do montante a ser indenizado. Dessa
                  forma, a relação de perdas tem por intuito fundamentar a
                  reparação justa, abrangendo tanto a indenização monetária, bem
                  como a restituição do direito à moradia adequada quando o
                  núcleo se enquadra entre os critérios estabelecidos
                  judicialmente.
                </p>
                <p>
                  Os dados georreferenciados neste site, provenientes das
                  informações coletadas nas vistorias in loco e das declarações
                  de cada Núcleo Familiar, compõem os mapas comunitários, que
                  buscam evidenciar a verdadeira vocação da comunidade, sendo
                  compostos por três camadas de informações: Usos e ocupações do
                  solo; Recursos Hídricos; e Referências Comunitárias. Veremos a
                  seguir como a equipe formulou e concebeu cada uma dessas
                  camadas.
                </p>
                <p>
                  Para a composição dos mapas comunitários vistos ao acessar o
                  site, integramos as três camadas citadas. A criação é um
                  mosaico oriundo das declarações individuais de cada um dos
                  mais de 900 Núcleos Familiares que concluíram as etapas 2 e 3
                  do processo de cadastramento realizado pela Cáritas (Figura
                  1). Apenas suprimimos informações que consideramos delicadas,
                  como a identificação dos núcleos e a delimitação de terrenos.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="assessoria_card d-flex flex-column justify-center align-center"
        >
          <span
            @click="showOverlayer.fig1 = !showOverlayer.fig1"
            class="d-flex justify-center align-center"
          >
            <p style="font-size: 0.5em;" class="mb-0 cursor-pointer">
              <strong>ampliar imagem</strong>
            </p>
            <v-icon small color="#000" class="cursor-pointer">
              mdi-magnify-plus-outline
            </v-icon></span
          >
          <v-overlay opacity="1" v-if="showOverlayer.fig1" class="text-center">
            <img
              width="100%"
              height="100%"
              src="../assets/projeto_mapa_comunitario_03.png"
              alt=""
            />
            <v-btn small color="#E06919" @click="showOverlayer.fig1 = false">
              fechar
            </v-btn>
          </v-overlay>
          <img
            max-width="100%"
            max-height="100%"
            src="../assets/projeto_mapa_comunitario_03.png"
            alt=""
          />
          <p style="font-size: 0.5em;">
            Figura 1: Mapa de uso e ocupação do solo elaborado para um dos
            núcleos familiares da zona rural de Mariana, que concluiu as etapas
            2 e 3 do processo de cadastro. Elaboração: Isabela Itabaiana, 2019.
          </p>
        </v-col>
      </v-row>
    </section>
    <section class="container">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="8" class="d-flex justify-center align-center">
          <Card
            class="sobre__projeto_card"
            borderColor="#E06919"
            titleColor="#E06919"
          >
            <template v-slot:conteudo>
              <p class="ma-0">
                Esta composição foi realizada para cada comunidade. Criamos,
                assim, um grande mapa de todas as áreas declaradas para as
                comunidades de Bento Rodrigues, Paracatu de Baixo, Paracatu de
                Cima, Bicas, Borba, Braúnas, Camargos, Campinas, Monsenhor
                Horta, Pedras e Ponte do Gama, que em sua totalidade compõem
                esse grande mapa que você vê ao acessar o site.
              </p>
              <p>
                O trabalho foi feito a partir da união dos shapefiles<sup
                  >2</sup
                >
                e, concomitantemente, foi realizada a organização das
                informações contidas nas tabelas de atributos de cada núcleo
                familiar inserido no cadastro. Posteriormente, realizou-se a
                correção de erros na topologia dos terrenos, visto que as
                informações constituem-se por autodeclaração de usos realizados
                por núcleos familiares distintos, que possuem percepções
                diferentes dos espaços. Diante disso, em determinados casos em
                que houve declarações de um mesmo terreno por diferentes núcleos
                familiares, dado o comum uso associado das unidades, o trabalho
                consistiu na união das diferentes unidades de mapeamento de
                forma a se obter sempre a representação mais diversificada dos
                usos.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>
    <section class="container">
      <v-row class="d-flex justify-center flex-md-row-reverse">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card
              class="sobre__projeto_card"
              title="Usos e ocupações"
              title_alignment="start"
              borderColor="#E06919"
              titleColor="#E06919"
            >
              <template v-slot:conteudo>
                <p>
                  Os “Usos e Ocupações” indicam o modo como as atividades
                  humanas se inserem no território, considerando os aspectos
                  objetivos, materiais e imateriais. No que tange ao processo de
                  cadastramento em Mariana, o mapeamento do uso e ocupação do
                  solo<sup>3</sup> visa explicitar áreas e descrever os bens
                  materiais e atividades econômicas declarados por cada Núcleo
                  Familiar. Essas declarações foram mapeadas utilizando camadas
                  vetoriais representadas por pontos e polígonos.
                </p>
                <p>
                  As camadas que compõem o site constituem a síntese dos bens
                  materiais e atividades econômicas levantadas ao longo do
                  processo de cadastramento e representam o ordenamento das
                  atividades no território antes do rompimento da barragem.
                  Nestas, foram utilizadas imagens de satélite referentes aos
                  meses anteriores ao rompimento.
                </p>
                <p>
                  A camada formada por polígonos é o resultado do mapeamento de
                  unidades passíveis de fotointerpretação das imagens de
                  satélite utilizadas para a confecção dos mapas individuais de
                  uso e ocupação de cada núcleo familiar e consequente
                  construção dos mapas comunitários (Figura 2). Os usos e
                  ocupações foram categorizados como: bambuzal, brejo,
                  chiqueiro, curral, estábulo, galinheiro, habitação, horta,
                  jardim, mata nativa, outras edificações, pastagem,
                  piscicultura, plantação perene, pomar, quintal, roça,
                  silvicultura.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="assessoria_card d-flex flex-column align-center justify-center"
        >
          <span
            @click="showOverlayer.fig2 = !showOverlayer.fig2"
            class="d-flex justify-center align-center"
          >
            <p style="font-size: 0.5em;" class="mb-0 cursor-pointer">
              <strong>ampliar imagem</strong>
            </p>
            <v-icon small color="#000" class="cursor-pointer">
              mdi-magnify-plus-outline
            </v-icon></span
          >
          <v-overlay opacity="1" v-if="showOverlayer.fig2" class="text-center">
            <img
              max-width="100%"
              max-height="100%"
              src="../assets/projeto_mapa_comunitario_04.png"
              alt=""
            />
            <v-btn small color="#E06919" @click="showOverlayer.fig2 = false">
              fechar
            </v-btn>
          </v-overlay>
          <img
            max-width="100%"
            max-height="100%"
            src="../assets/projeto_mapa_comunitario_04.png"
            alt=""
          />
          <p style="font-size: 0.5em;">
            Figura 2: Exemplificação da junção dos usos e ocupações do solo
            caracterizados por polígonos durante o processo de cadastro para
            representação no site. Fonte: Acervo Cáritas, 2022.
          </p>
        </v-col>
      </v-row>
    </section>
    <section class="container">
      <v-row class="d-flex justify-center">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card
              class="sobre__projeto_card"
              borderColor="#E06919"
              titleColor="#E06919"
            >
              <template v-slot:conteudo>
                <p>
                  Já a camada formada por pontos, caracteriza-se por unidades de
                  usos e ocupação que foram apontadas sua localização pelos
                  núcleos familiares, contudo não foram passíveis de
                  visualização/fotointerpretação das mesmas imagens de satélite
                  (Figura 3). As camadas que representam usos e ocupações e
                  foram mapeadas como pontos, se ordenam como: habitação,
                  apicultura, chiqueiro, curral, estábulo, fornos de carvão,
                  galinheiro, roça, horta, jardim, viveiro, moinho e outras
                  edificações.
                </p>
                <p>
                  Na análise dos mapas individuais, observou-se a frequência de
                  declarações das unidades de 'Roça' e 'Curral' que evidenciam
                  como essas atividades eram desenvolvidas com frequência nas
                  comunidades atingidas. Muitos dos núcleos familiares se
                  caracterizam por serem agricultores da agricultura familiar e
                  estarem dentro de uma área com aptidão pecuária voltada para
                  produção de leite. Além desses usos, nas idas a campo notamos
                  a frequência de bambuzais no território, sendo então destacada
                  essa ocupação das demais em função de sua importância para o
                  conforto térmico dos terrenos, e também da utilização desse
                  material para a confecção de varas de pesca, construção de
                  cercas, entre outros usos.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="assessoria_card d-flex flex-column justify-center align-center"
        >
          <span
            @click="showOverlayer.fig3 = !showOverlayer.fig3"
            class="d-flex justify-center align-center"
          >
            <p style="font-size: 0.5em;" class="mb-0 cursor-pointer">
              <strong>ampliar imagem</strong>
            </p>
            <v-icon small color="#000" class="cursor-pointer">
              mdi-magnify-plus-outline
            </v-icon></span
          >
          <v-overlay opacity="1" v-if="showOverlayer.fig3" class="text-center">
            <img
              max-width="100%"
              max-height="100%"
              src="../assets/projeto_mapa_comunitario_05.png"
              alt=""
            />
            <v-btn small color="#E06919" @click="showOverlayer.fig3 = false">
              fechar
            </v-btn>
          </v-overlay>
          <img
            max-width="100%"
            max-height="100%"
            src="../assets/projeto_mapa_comunitario_05.png"
            alt=""
          />
          <p style="font-size: 0.5em;">
            Figura 3: Exemplificação da junção dos usos e ocupações do solo
            caracterizados por pontos durante o processo de cadastro para
            representação no site. Elaboração: Acervo Cáritas, 2022.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="10"
          md="7"
          class="d-flex justify-center align-center border__image"
        >
          <p class="ma-0">
            “No meu caso, eu tinha muita água antes do rompimento, tinha rio a
            meu favor, que me ajudava com as criações, e água diretamente da
            nascente para usos dentro de casa. Sem água é quase impossível
            manter minhas criações no reassentamento, por isso, a gente está
            lutando para conseguir ter essa água bruta no novo Paracatu (<a
              target="_blank"
              style="text-decoration: none"
              href="https://issuu.com/jornalasirene/docs/edi__o_60_-_jornal_a_sirene__corrigida_ano_6_"
              class="pink--text"
              >BARROS; ASSIS, 2021, p.14</a
            >).”
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="5"
          class="assessoria_card d-flex flex-column align-center justify-center"
        >
          <span
            @click="showOverlayer.fig4 = !showOverlayer.fig4"
            class="d-flex justify-center align-center"
          >
            <p style="font-size: 0.5em;" class="mb-0 cursor-pointer">
              <strong>ampliar imagem</strong>
            </p>
            <v-icon small color="#000" class="cursor-pointer">
              mdi-magnify-plus-outline
            </v-icon></span
          >
          <v-overlay opacity="1" v-if="showOverlayer.fig4" class="text-center">
            <img
              max-width="100%"
              max-height="100%"
              src="../assets/projeto_mapa_comunitario_06.png"
              alt=""
            />
            <v-btn small color="#E06919" @click="showOverlayer.fig4 = false">
              fechar
            </v-btn>
          </v-overlay>
          <img
            max-width="100%"
            max-height="100%"
            src="../assets/projeto_mapa_comunitario_06.png"
            alt=""
          />
          <p style="font-size: 0.5em;">
            Figura 4: Exemplificação da junção dos recursos hídricos
            caracterizados por pontos e linhas declarados durante o processo de
            cadastro para representação no site. Elaboração: Acervo Cáritas,
            2022.
          </p>
        </v-col>
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card
              class="sobre__projeto_card"
              borderColor="#E06919"
              titleColor="#E06919"
              title="Recursos Hídricos"
            >
              <template v-slot:conteudo>
                <p>
                  Uma grande variabilidade de recursos hídricos foi mapeada nas
                  comunidades atingidas, o que demonstra o acesso abundante à
                  água que as populações possuíam. Dentre as unidades mapeadas
                  no processo de cadastramento, foram identificados, através da
                  coleta de informações por autodeclaração ou fotointerpretação
                  de imagens de satélite, o Rio Gualaxo do Norte, pisciculturas,
                  cachoeiras, reservatórios comunitários de água e um grande
                  número de nascentes e córregos.
                </p>
                <p>
                  Para além disso, foram identificados e denominados como corpos
                  hídricos outras unidades mapeadas que englobam açudes, poços
                  de peixes, entre outros recursos hídricos, que porventura não
                  puderam ser caracterizados ao longo do cadastro, que possui
                  como premissa a autodeclaração dos núcleos familiares (Figura
                  4) (Figura 5). Cabe ressaltar que, a riqueza dos recursos
                  hídricos presentes nas comunidades atingidas, extrapolam os
                  dados coletados no processo de cadastramento, visto que no
                  momento do cadastro as informações levantadas foram baseadas
                  na autodeclaração e devido ao passar do tempo e
                  descaracterização das áreas, por vezes tais informações não
                  puderam ser rememoradas.
                </p>
                <p>
                  O acesso à água nos processos de reparação, como é o caso dos
                  reassentamentos, é fundamental para a manutenção das criações
                  e plantações, garantindo assim o restabelecimento dos modos de
                  vida das populações atingidas. Para o Sr. Jerônimo Batista,
                  morador de Paracatu de Baixo, infelizmente o acesso à água nos
                  reassentamentos não condiz com as informações levantadas, como
                  era em seu terreno de origem.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>
    <section class="container">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="5"
          class="assessoria_card d-flex flex-column justify-center align-center"
        >
          <v-img
            max-width="100%"
            max-height="100%"
            src="../assets/projeto_mapa_comunitario_07.png"
            alt=""
          />
          <p style="font-size: 0.5em;">
            Figura 5: Cachoeira de água cristalina em Bento Rodrigues. Foto:
            Cristiano Sales, A Sirene.
          </p>
        </v-col>
      </v-row>
    </section>
    <section class="container">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="8" class="d-flex justify-center align-center">
          <Card
            class="sobre__projeto_card"
            borderColor="#E06919"
            titleColor="#E06919"
          >
            <template v-slot:conteudo>
              <p class="ma-0">
                Diante de estudos realizados, outro fator agravante é a
                contaminação dos recursos hídricos das comunidades atingidas,
                como é o caso da contaminação do Rio Gualaxo do Norte por metais
                pesados, o que inviabiliza a utilização de suas águas,
                prejudicando assim também a retomada dos modos de vida das
                comunidades. O direito à água é um dos direitos fundamentais
                para a manutenção à vida, sendo assim, os mapas buscam
                evidenciar a riqueza hídrica das comunidades atingidas em
                Mariana com base na autodeclaração do processo de cadastro.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>
    <section class="container">
      <v-row class="d-flex justify-center">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card
              class="sobre__projeto_card"
              borderColor="#E06919"
              titleColor="#E06919"
              title="Referências Comunitárias"
            >
              <template v-slot:conteudo>
                <p>
                  A memória das pessoas tem sido a principal fonte de acesso ao
                  conjunto de informações que englobam o processo de
                  constituição histórica de centenas de famílias que foram
                  violentamente atingidas pelo rompimento. Dessa forma, a
                  memória passou a ser considerada como a principal fonte de
                  acesso a todas as lembranças de histórias e fatos vivenciados
                  cotidianamente, que conformavam o modo de vida dos atingidos e
                  atingidas e apresentavam a relação simbólica com seu lugar de
                  origem, traduzidos na forma de vínculos e pertencimento.
                </p>
                <p>
                  No processo de cadastramento muitas famílias declararam a
                  perda da relação que existia constituída em suas comunidades
                  de origem com os bens aos quais se sentiam pertencentes e que
                  faziam parte do cotidiano. Sendo assim, os mapas apresentam as
                  referências comunitárias georreferenciadas elencadas pelos
                  atingidos e atingidas nas etapas do processo de cadastro, como
                  as igrejas e capelas, bares, campos de futebol, cemitérios,
                  escolas, quadras poliesportiva, postos de saúde, postos de
                  correio, cruzeiros, muros de pedras e reservatórios de água
                  comunitário (Figura 6).
                </p>
              </template>
            </Card>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="assessoria_card d-flex flex-column justify-center align-center"
        >
          <span
            @click="showOverlayer.fig6 = !showOverlayer.fig6"
            class="d-flex justify-center align-center"
          >
            <p style="font-size: 0.5em;" class="mb-0 cursor-pointer">
              <strong>ampliar imagem</strong>
            </p>
            <v-icon small color="#000" class="cursor-pointer">
              mdi-magnify-plus-outline
            </v-icon></span
          >
          <v-overlay opacity="1" v-if="showOverlayer.fig6" class="text-center">
            <img
              max-width="100%"
              max-height="100%"
              src="../assets/projeto_mapa_comunitario_08.png"
              alt=""
            />
            <v-btn small color="#E06919" @click="showOverlayer.fig6 = false">
              fechar
            </v-btn>
          </v-overlay>
          <img
            max-width="100%"
            max-height="100%"
            src="../assets/projeto_mapa_comunitario_08.png"
            alt=""
          />
          <p style="font-size: 0.5em;">
            Figura 6: Exemplificação da junção dos recursos hídricos
            caracterizados por pontos e linhas declarados durante o processo de
            cadastro para representação no site. Elaboração: Acervo Cáritas,
            2022.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="7"
          class="assessoria_card d-flex flex-column justify-center align-center"
        >
          <Card class="sobre__projeto_card" borderColor="#E06919">
            <template v-slot:conteudo>
              <p>
                Para a senhora Mônica dos Santos, a Igreja de São Bento, hoje em
                ruínas, sempre foi uma referência para a sua vida e comunidade:
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="10"
          md="5"
          class="d-flex justify-center align-center border__image"
        >
          <p class="ma-0" style="z-index: 10;">
            “Eu morava ao lado da igreja de São Bento. Meu tio André ficou com
            uma cópia da chave por um tempo, na casa da minha avó, então era
            aquela coisa de estar todo dia na igreja, ajudando na leitura, na
            condução da reza, das missas. Então, assim, é uma ligação muito
            forte que eu tenho com a igreja de São Bento. (<a
              target="_blank"
              style="text-decoration: none"
              href="https://issuu.com/jornalasirene/docs/edi_o_63_-_jornal_a_sirene_-_julho_de_2021"
              class="pink--text"
              >SILVA; SANTOS, 2021, p. 10 </a
            >).”
          </p>
        </v-col>
      </v-row>
    </section>
    <section class="container">
      <v-row class="d-flex flex-column justify-center align-center">
        <v-col cols="12" md="6" class="d-flex justify-center align-center">
          <v-img
            max-width="100%"
            max-height="100%"
            src="../assets/projeto_mapa_comunitario_09.png"
            alt=""
          />
        </v-col>
        <v-col cols="5">
          <p style="font-size: 0.5em; text-align: center;">
            Figura 7: Igreja de São Bento antes de ser destruída pela lama.
            Foto: Compat Mariana, Queiroz et al., 2021.
          </p>
        </v-col>
      </v-row>
    </section>
    <section class="container">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="7"
          class="assessoria_card d-flex justify-center align-center"
        >
          <Card class="sobre__projeto_card" borderColor="#E06919">
            <template v-slot:conteudo>
              <p>
                De acordo com a senhora Maria Geralda, de Paracatu de Baixo, a
                igreja de Santo Antônio representa um local de encontro e
                partilha, o que justifica tamanho apreço, cuidado e zelo pelo
                local:
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="10"
          md="5"
          class="d-flex justify-center align-center border__image"
        >
          <p class="ma-0" style="z-index: 10;">
            “[...] além de ser nossa igreja, a única que temos, a utilizamos em
            várias ocasiões, como velórios, festas, entre outras coisas. Para
            tudo o que acontece em nossa comunidade, a igreja serve como ponto
            de apoio. É o lugar que nós usamos para tudo. Todo esse zelo e
            cuidado é por amor ao nosso patrimônio (<a
              target="_blank"
              style="text-decoration: none"
              href="https://issuu.com/jornalasirene/docs/edi__o_58_-_fevereiro_de_2021_-_jornal_a_sirene__c"
              class="pink--text"
              >QUEIROZ et al., 2021, p. 8</a
            >).”
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-center">
        <v-col class="d-flex justify-center align-center">
          <div class="inside__card creators__box">
            <p class="text--primary mb-0 pl-5 pr-5 text-align-center">
              Autores: Gabriel Mateus Silva Leite <sup>4</sup>, Priscila
              Aparecida da Rocha Honorato <sup>5</sup>
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
    <section class="container">
      <v-row class="d-flex">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p>
              <sup>1</sup> Áreas cujos usos e ocupações possuem características
              rurais, mas são assistidas por infraestrutura urbana.
            </p>

            <p>
              <sup>2</sup> Formato de armazenamento de dados de vetor para
              armazenar a posição, a forma e os atributos de feições
              geográficas.
            </p>

            <p>
              <sup>3</sup> A nomenclatura foi pré-estabelecida a partir do
              manual técnico de uso da terra do Instituto Brasileiro de
              Geografia e Estatística - IBGE. Rio de Janeiro: IBGE, 2013. 3ª
              Edição.
            </p>

            <p>
              <sup>4</sup> Arquiteto e Urbanista pela UFOP, Engenheiro Civil
              pela FASAR. Referência Técnica na ATI Mariana.
            </p>
            <p>
              <sup>5</sup> Engenheira Agrônoma pela UFV., Assessora Técnica na
              ATI Mariana.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
    <section class="container">
      <v-row class="d-flex">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p><strong>Referências Bibliográficas</strong></p>

            <p>
              BARROS, Ellen; ASSIS, Geovane. Água: direito à vida e justiça para
              as famílias atingidas. <strong>A Sirene</strong>, Mariana, ano 6,
              ed. 60, p. 14, abr. 2021. Disponível em:
              <a
                target="_blank"
                style="text-decoration: none"
                href="https://issuu.com/jornalasirene/docs/edi__o_60_-_jornal_a_sirene__corrigida_ano_6_"
                class="pink--text"
                >https://issuu.com/jornalasirene/docs/edi__o_60_-_jornal_a_sirene__corrigida_ano_6_</a
              >. Acesso em: 24 jan. 2022.
            </p>
            <p>
              LEITE, Gabriel.
              <strong>Estudos sobre territórios minerados:</strong> A utilização
              da cartografia social familiar no processo de cadastramento dos
              atingidos e atingidas pelo rompimento da barragem do Fundão em
              Mariana/MG. TFG (Graduação em Arquitetura e Urbanismo). Escola de
              Minas, Universidade Federal de Ouro Preto. Ouro Preto, 2022.
            </p>
            <p>
              SILVA, Mauro; SANTOS, Mônica. Patrimônio sagrado.
              <strong>A Sirene</strong>, Mariana, ano 6, ed. 63, p. 10, julho.
              2021. Disponível em:
              <a
                target="_blank"
                style="text-decoration: none"
                href="https://issuu.com/jornalasirene/docs/edi_o_63_-_jornal_a_sirene_-_julho_de_2021"
                class="pink--text"
                >https://issuu.com/jornalasirene/docs/edi_o_63_-_jornal_a_sirene_-_julho_de_2021</a
              >. Acesso em: 25 jan. 2022.
            </p>
            <p>
              QUEIROZ, Luzia et al. As igrejas são o nosso ponto de apoio.
              <strong>A Sirene</strong>, Mariana, ano 6, e. 58, p. 8, fev. 2021.
              Disponível em:
              <a
                target="_blank"
                style="text-decoration: none"
                href="https://issuu.com/jornalasirene/docs/edi__o_58_-_fevereiro_de_2021_-_jornal_a_sirene__c"
                class="pink--text"
                >https://issuu.com/jornalasirene/docs/edi__o_58_-_fevereiro_de_2021_-_jornal_a_sirene__c</a
              >. Acesso em: 25 jan. 2022.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";

export default {
  components: { Card },
  data() {
    return {
      showOverlayer: {
        fig1: false,
        fig2: false,
        fig3: false,
        fig4: false,
        fig6: false,
      },
    };
  },
};
</script>

<style scoped>
section {
  margin-top: 2em;
  margin-bottom: 2em;
}

.creators__box p {
  text-align: center;
  font-weight: bold;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
}

.inside__card p {
  font-size: 0.6em;
}

@media (max-width: 600px) {
  section {
    margin-top: 0;
    margin-bottom: 0;
  }

  .border__image {
    background-color: rgba(224, 105, 25, 0.3);
    padding: 0;
    border-radius: 12px;
  }

  .border__image::before {
    display: none;
  }
}

.border__image {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../assets/sobre_border_image_02.png");
  background-size: 100% 100%;
}

.border__image p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
  z-index: 20;
}

.assessoria_card p {
  padding: 0.6em;
  text-align: center;
  font-size: 0.7em;
}
</style>
